.simulator-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.simulatorSubmitButton {
    margin-top: 10%;
}
.simulatorResultsTableHeader {
    font-size: 18px;
}
.googleCalendarIcon {
    height: 16px;
    width: 16px;
    cursor: pointer;
}
.resultsMainDiv {
    text-align: center;;
    /* max-width:100%;
    white-space:nowrap; */
}
.restulsTable {
    border-collapse: collapse;
}
.restulsTable thead{
    font-weight: bold;
}
.restulsTable td{
    padding: 10px;
}
.restulsTableTr {
    border: solid;
    border-width: 1px 0;
    border-color: darkgray;
    margin: 20%;
}
/* .restulsTableTd  {
    padding-top: 20px;
} */
/*Prevent borders from */
/* tr:first-child {
    border-top: none;
}
  tr:last-child {
    border-bottom: none;
} */